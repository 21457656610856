.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50% !important;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-spotify {
  background: #1ecd97;
  border-radius: 2em;
  border: 0.2em solid #1ecd97;
  color: #1ecd97;
  cursor: pointer;
  padding: 0.7em 1.5em;
  text-transform: uppercase;
  color: white;
  font-weight: bold;

  margin: 0 auto;
}

.btn-spotify:hover {
  color: white;
  text-decoration: none;
  filter: brightness(1.1);
}

.btn-spotify-small {
  background: #1ecd97;
  border-radius: 2em;
  border: 0.2em solid #1ecd97;
  color: #1ecd97;
  cursor: pointer;
  padding: 0.2em 0.8em;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  margin: 10px;
}

.btn-spotify-small:hover {
  color: white;
  text-decoration: none;
  filter: brightness(1.1);
}

.player-wrapper {
  width: 100%;
  height: 100%;
  min-height: 250px;
}

.login-wrapper {
  width: 100%;
  min-height: inherit;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spotify-wrapper {
  width: 100%;
  min-height: inherit;
  height: 100%;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: table;
}

.white-text-centered {
  display: table-cell;
  color: white;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-responsive::-webkit-scrollbar {
  width: 14px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
}

.table-responsive::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.table-responsive::-webkit-scrollbar-corner {
  background-color: transparent;
}

.table-responsive {
  overflow: scroll;
  color: #00000000;
  transition: color 0.3s;
  height: 500px;
}

.table-responsive:hover {
  color: #666666ff;
}
